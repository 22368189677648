/* eslint-disable indent */
import { useState } from "react";
// @mui
import { Stack, Button, Tooltip, IconButton, Typography } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Iconify from "../../components/iconify";
import { useSharedContext } from "../SharedComponentsContext";
import Label from "../../components/label";
import { useBitrixContext } from "../../api/bitrix/context/BitrixContext";
import { dbContact } from "../../api/parnerus/types/contact";
import axiosInstance from "../../utils/axios";
import { dbCompany } from "../../api/parnerus/types/company";
import InvoiceDocument from "./InvoiceDocument";
import ReactPDF, { PDFDownloadLink } from "@react-pdf/renderer";
import { sendDocument } from "../../api/parnerus/services/sendDocument";
import { useSnackbar } from "../../components/snackbar";
import { useAuthContext } from "../../auth/useAuthContext";
import { dbAccount } from "../../api/parnerus/types/account";
import axios from "../../utils/axios";
import ConfirmDialog from "../../components/confirm-dialog";

// ----------------------------------------------------------------------

type Props = {
  fileInputRef: React.RefObject<HTMLInputElement>;
  taskName: string;
  liked: boolean;
  completed: boolean;
  onLike: VoidFunction;
  onAttach: VoidFunction;
  onCompleted: VoidFunction;
  onCloseDetails: VoidFunction;
  contact: dbContact | undefined;
  company: dbCompany | undefined;
};

export default function InvoiceDetailsToolbar({
  fileInputRef,
  taskName,
  liked,
  completed,
  onLike,
  onAttach,
  onCompleted,
  onCloseDetails,
  contact,
  company,
}: Props) {
  const { account } = useAuthContext();
  const {
    invoice,
    openInvoiceEditSlider,
    openPaymentModal,
    triggerLoading,
    closeInvoiceViewSlider,
    openUpgradeModal,
  } = useSharedContext();
  const { user } = useAuthContext();
  const { productsList } = useBitrixContext();
  const [isLoading, setIsLoading] = useState(false);
  const isDesktop = useResponsive("up", "sm");
  const isMobile = useResponsive("down", "sm");
  const { enqueueSnackbar } = useSnackbar();

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const orderCreateHandler = async () => {
    if (invoice) {
      setIsLoading(true);

      const contact: dbContact = await axiosInstance
        .get(
          `/api/contact/list-by-id?contactId=${invoice.contactId}&accountId=${account?.accountId}`
        )
        .then((response) => response.data.results[0]);

      const company: dbCompany | undefined = invoice.companyId
        ? await axiosInstance
            .get(
              `/api/company/list-by-id?companyId=${invoice.companyId}&accountId=${account?.accountId}`
            )
            .then((response) => response.data.results[0])
        : undefined;

      openPaymentModal({
        contact,
        company,
        products: invoice.products,
        files: [],
        accountId: account?.accountId,
        productsList,
        mediadorId: user?.bitrixId,
        invoiceId: invoice.invoiceId,
      });
      setIsLoading(false);
    }
  };

  const sendInvoiceHandler = async () => {
    if (!contact?.EMAIL) {
      enqueueSnackbar(
        "El cliente seleccionado no tiene correo electrónico. Por favor, edite el contacto y vuelva a intentarlo.",
        { variant: "error" }
      );
      return;
    }

    if (invoice) {
      const documentBlob = await ReactPDF.pdf(
        <InvoiceDocument
          invoice={invoice}
          user={user}
          contact={contact}
          company={company}
          account={account as dbAccount}
        />
      ).toBlob();
      sendDocument(
        documentBlob,
        contact.EMAIL,
        `Factura de ${account?.name}`,
        `Has recibido una factura de ${account?.name}. Puedes encontrar dicha factura adjunta en este correo.`,
        account?.accountId
      )
        .then(() => {
          enqueueSnackbar("Factura enviada.", { variant: "success" });
        })
        .catch(() => {
          enqueueSnackbar(
            "Hubo un error al enviar la factura. Por favor, revise la configuración de correo de su cuenta.",
            { variant: "error" }
          );
        });
    }
  };

  const handleDelete = async (id: string | undefined) => {
    await axios.post("/api/invoice/disable", {
      invoiceId: id,
    });
    triggerLoading();
    closeInvoiceViewSlider();
  };

  return (
    <>
      <Stack p={2.5} direction="row" alignItems="center">
        {!isDesktop && (
          <Tooltip title="Back">
            <IconButton onClick={onCloseDetails} sx={{ mr: 1 }}>
              <Iconify icon="eva:arrow-ios-back-fill" />
            </IconButton>
          </Tooltip>
        )}

        <Typography sx={{ mr: 1 }}>
          Factura #{invoice?.invoiceId.split("-")[0].toUpperCase()}
        </Typography>

        {invoice && !isMobile && (
          <Label
            variant="soft"
            color={
              (invoice.status === "Paid" && "success") ||
              (invoice.status === "Sent to customer" && "warning") ||
              "default"
            }
          >
            {invoice.status}
          </Label>
        )}

        {isDesktop && !invoice?.enabledItem && (
          <Label
            variant="soft"
            color="error"
            sx={{ textTransform: "capitalize", ml: 2 }}
          >
            Deshabilitada
          </Label>
        )}

        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          flexGrow={1}
        >
          {invoice && (
            <Tooltip title="Descargar PDF">
              <PDFDownloadLink
                document={
                  <InvoiceDocument
                    invoice={invoice}
                    user={user}
                    contact={contact}
                    company={company}
                    account={account as dbAccount}
                  />
                }
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    enqueueSnackbar(
                      "Factura descargada. Revise su carpeta de descargas.",
                      { variant: "success" }
                    )
                  }
                >
                  <Iconify icon="teenyicons:pdf-solid" />
                </IconButton>
              </PDFDownloadLink>
            </Tooltip>
          )}
          <Tooltip title="Enviar al cliente">
            <IconButton
              size="small"
              onClick={() => {
                // if (
                //   !user?.subscription?.plan ||
                //   user?.subscription?.plan === "Freemium"
                // ) {
                //   openUpgradeModal(
                //     `El envío de documentos por correo está disponible en los planes Empresa y Parnerus. Si quieres continuar con la versión ${user?.subscription?.plan} puedes seguir disfrutando nuestras otras herramientas.`
                //   );
                //   return;
                // }
                sendInvoiceHandler();
              }}
            >
              <Iconify icon="tabler:send" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar">
            <IconButton
              size="small"
              onClick={() => openInvoiceEditSlider(invoice)}
            >
              <Iconify icon="material-symbols:edit-sharp" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar">
            <IconButton
              size="small"
              onClick={() => handleOpenConfirm()}
              color="error"
            >
              <Iconify icon="ph:trash-bold" />
            </IconButton>
          </Tooltip>

          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => orderCreateHandler()}
            size="small"
            disabled={isLoading}
          >
            Orden
          </Button>
        </Stack>
      </Stack>

      <input ref={fileInputRef} type="file" style={{ display: "none" }} />

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Eliminar"
        content="¿Estás seguro que deseas eliminar este registro? Esto deshabilitará el registro, y sólo quedará visible para los registros que estén relacionado al mismo, pero no podrás encontrarlo más en este listado"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(invoice?.invoiceId)}
          >
            Eliminar
          </Button>
        }
      />
    </>
  );
}
