import { useState, useRef, useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
// components
import Scrollbar from "../../components/scrollbar";
//
import { format } from "date-fns";
import QuoteViewToolbar from "./QuoteViewToolbar";
import { useSharedContext } from "../SharedComponentsContext";
import axiosInstance from "../../utils/axios";
import { dbContact } from "../../api/parnerus/types/contact";
import Iconify from "../../components/iconify";
import { dbCompany } from "../../api/parnerus/types/company";
import InvoicesTable, {
  InvoicesTableVariants,
} from "../../pages/CRM/Invoices/InvoicesTable";
import ReactPDF from "@react-pdf/renderer";
import QuoteDocument from "./QuoteDocument";
import { useAuthContext } from "../../auth/useAuthContext";
import { useBitrixContext } from "../../api/bitrix/context/BitrixContext";
import { dbAccount } from "../../api/parnerus/types/account";
import useResponsive from "../../hooks/useResponsive";

// ----------------------------------------------------------------------

const StyledLabel = styled("span")(({ theme }) => ({
  ...theme.typography.caption,
  width: 120,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

type Props = {
  isOpen: boolean;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`contact-tabpanel-${index}`}
      aria-labelledby={`contact-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function QuoteDetails({ isOpen }: Props) {
  const { account } = useAuthContext();
  const { closeQuoteViewSlider } = useSharedContext();
  const { productsList } = useBitrixContext();
  const { quote, openContactViewSlider, openCompanyViewSlider } =
    useSharedContext();
  const { user } = useAuthContext();
  const isMobile = useResponsive("down", "sm");
  const [contact, setContact] = useState<dbContact>();
  const [company, setCompany] = useState<dbCompany>();

  const [tabSelected, setTabSelected] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [liked, setLiked] = useState(false);

  const [completed, setCompleted] = useState(true);

  const handleLiked = () => {
    setLiked(!liked);
  };

  const handleCompleted = () => {
    setCompleted(!completed);
  };

  const handleClickAttach = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    if (quote && quote.quoteId) {
      if (quote?.contactId) {
        axiosInstance
          .get(
            `/api/contact/list-by-id?contactId=${quote.contactId}&accountId=${account?.accountId}`
          )
          .then((response) => setContact(response.data.results[0]));
      }

      if (quote?.companyId) {
        axiosInstance
          .get(
            `/api/company/list-by-id?companyId=${quote.companyId}&accountId=${account?.accountId}`
          )
          .then((response) => setCompany(response.data.results[0]));
      }
    }
  }, [quote]);

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={closeQuoteViewSlider}
        anchor="right"
        PaperProps={{
          sx: {
            width: {
              xs: 1,
              sm: 900,
            },
          },
        }}
      >
        <QuoteViewToolbar
          taskName={quote?.quoteId || ""}
          fileInputRef={fileInputRef}
          liked={liked}
          completed={completed}
          onLike={handleLiked}
          onAttach={handleClickAttach}
          onCompleted={handleCompleted}
          onCloseDetails={closeQuoteViewSlider}
          contact={contact}
          company={company}
        />

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ px: 2.5, pt: 1, pb: 5 }}>
            <Tabs
              value={tabSelected}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Información general" />
              <Tab label="Visualizar documento" />
              <Tab label="Facturas" />
            </Tabs>

            <CustomTabPanel value={tabSelected} index={0}>
              <Stack spacing={3}>
                <Typography variant="overline">Cliente</Typography>

                <Stack direction={isMobile ? "column" : "row"} gap={3}>
                  <Stack direction="column" width={200}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Contacto
                    </StyledLabel>
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      alignItems="center"
                      onClick={() => openContactViewSlider(contact)}
                      sx={{ cursor: "pointer", textTransform: "capitalize" }}
                    >
                      {quote?.contactName}
                      <Iconify icon="ph:link-light" />
                    </Stack>
                  </Stack>

                  <Stack direction="column" width={200}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Compañía
                    </StyledLabel>

                    {quote?.companyName ? (
                      <Stack
                        direction="row"
                        flexWrap="wrap"
                        alignItems="center"
                        onClick={() => openCompanyViewSlider(company)}
                        sx={{ cursor: "pointer", textTransform: "capitalize" }}
                      >
                        {quote?.companyName}
                        <Iconify icon="ph:link-light" />
                      </Stack>
                    ) : (
                      "-"
                    )}
                  </Stack>
                </Stack>

                <Divider />

                <Typography variant="overline" sx={{ pt: 3 }}>
                  Información
                </Typography>

                <Stack direction={isMobile ? "column" : "row"} gap={3}>
                  <Stack direction="column" width={200}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Vencimiento
                    </StyledLabel>

                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {quote && (
                        <Typography variant="caption">
                          {format(new Date(quote.dueDate), "dd MMM yyyy")}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>

                  <Stack direction="column" width={200}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Creado el
                    </StyledLabel>

                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {quote && (
                        <Typography variant="caption">
                          {format(new Date(quote.createdDate), "dd MMM yyyy")}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Stack>

                <Divider />

                <Typography variant="overline">DETALLES</Typography>

                <Stack direction="column">
                  <StyledLabel sx={{ height: 10, lineHeight: "10px", my: 0.5 }}>
                    Productos
                  </StyledLabel>

                  <Stack direction="row" flexWrap="wrap" alignItems="center">
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Descr.</TableCell>
                          <TableCell>Cant.</TableCell>
                          <TableCell>Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {quote?.products.map((product) => (
                          <TableRow key={product.id}>
                            <TableCell>
                              <Typography variant="overline">
                                {product.product}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                ${product.price}(Parnerus{" "}
                                {
                                  productsList.find((p) => p.ID === product.id)
                                    ?.PRICE
                                }
                                )<b>x</b>
                                {product.quantity}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                $
                                {Number(product.price) *
                                  Number(product.quantity)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Stack>
                </Stack>

                <Stack direction="column">
                  <StyledLabel sx={{ height: 10, lineHeight: "10px", my: 0.5 }}>
                    Importe total
                  </StyledLabel>

                  <Stack direction="row" flexWrap="wrap" alignItems="center">
                    ${" "}
                    {quote?.products.reduce(
                      (prev, current) => prev + current.total,
                      0
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </CustomTabPanel>

            <CustomTabPanel value={tabSelected} index={1}>
              {quote && (
                <Stack>
                  <ReactPDF.PDFViewer height={600}>
                    <QuoteDocument
                      quote={quote}
                      user={user}
                      contact={contact}
                      company={company}
                      account={account as dbAccount}
                    />
                  </ReactPDF.PDFViewer>
                </Stack>
              )}
            </CustomTabPanel>

            <CustomTabPanel value={tabSelected} index={2}>
              <Stack spacing={3}>
                {quote && (
                  <InvoicesTable
                    variant={InvoicesTableVariants.ContactAndCompany}
                    filterAdded={{
                      field: "quoteId",
                      value: quote.quoteId,
                    }}
                  />
                )}
              </Stack>
            </CustomTabPanel>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
