/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from "react";
// utils
import axios from "../utils/axios";
import localStorageAvailable from "../utils/localStorageAvailable";
//
import { isValidToken, setAccountSession, setSession } from "./utils";
import {
  AccountType,
  ActionMapType,
  AuthStateType,
  AuthUserType,
  JWTContextType,
} from "./types";
import { dbAccount } from "../api/parnerus/types/account";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = "INITIAL",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  LOGOUT = "LOGOUT",
  LOGIN_ACCOUNT = "LOGIN_ACCOUNT",
  LOGOUT_ACCOUNT = "LOGOUT_ACCOUNT",
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    isAccountAuthenticated: boolean;
    user: AuthUserType;
    account: AccountType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
  [Types.LOGIN_ACCOUNT]: {
    account: AccountType;
  };
  [Types.LOGOUT_ACCOUNT]: {
    account: AccountType;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  isVerifiedUser: false,
  isAccountAuthenticated: false,
  user: null,
  account: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      isAccountAuthenticated: action.payload.isAccountAuthenticated,
      isVerifiedUser: action.payload.user?.isVerified || false,
      user: action.payload.user,
      account: action.payload.account,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      isVerifiedUser: action.payload.user?.isVerified || false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      isVerifiedUser: action.payload.user?.isVerified,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      isAccountAuthenticated: false,
      isVerifiedUser: false,
      user: null,
      account: null,
    };
  }
  if (action.type === Types.LOGIN_ACCOUNT) {
    return {
      ...state,
      isAccountAuthenticated: true,
      account: action.payload.account,
    };
  }
  if (action.type === Types.LOGOUT_ACCOUNT) {
    return {
      ...state,
      isAccountAuthenticated: false,
      account: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable
        ? localStorage.getItem("accessToken")
        : "";
      const accountId = storageAvailable
        ? localStorage.getItem("accountId")
        : "";

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get("/api/account/my-account");
        const { user, subscription } = response.data;

        if (accountId) {
          setAccountSession(accountId);
          const responseAccount = await axios.get(
            `/api/account/get-account?accountId=${accountId}`
          );
          const { account } = responseAccount.data;

          let generatedSubscription;
          if (subscription && subscription.status === "ACTIVE") {
            generatedSubscription = {
              plan:
                subscription.plan_id ===
                process.env.REACT_APP_PAYPAL_PLAN_EMPRESA
                  ? "Empresa"
                  : subscription.plan_id ===
                    process.env.REACT_APP_PAYPAL_PLAN_PARNERUS
                  ? "Parnerus"
                  : "Freemium",
              dueDate: subscription.billing_info?.next_billing_time,
            };
          } else if (
            user.id === "8864c717-587d-472a-929a-8e5f298024da-0" ||
            user.id === "9fd04264-97c8-486e-8aee-03420b8c78dc"
          ) {
            generatedSubscription = {
              plan: "Parnerus",
              dueDate: "2030-01-01",
            };
          } else {
            generatedSubscription = {
              plan: "Freemium",
              dueDate: null,
            };
          }

          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: true,
              isAccountAuthenticated: true,
              user: {
                ...user,
                subscription: generatedSubscription,
              },
              account,
            },
          });
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: true,
              isAccountAuthenticated: false,
              user,
              account: null,
            },
          });
        }
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            isAccountAuthenticated: false,
            user: null,
            account: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          isAccountAuthenticated: false,
          user: null,
          account: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (email: string, password: string, pushToken: string) => {
      const response = await axios.post("/api/account/login", {
        email,
        password,
        pushToken,
      });
      const { accessToken, user, subscription } = response.data;

      setSession(accessToken);

      let generatedSubscription;
      if (subscription && subscription.status === "ACTIVE") {
        generatedSubscription = {
          plan:
            subscription.plan_id === process.env.REACT_APP_PAYPAL_PLAN_EMPRESA
              ? "Empresa"
              : subscription.plan_id ===
                process.env.REACT_APP_PAYPAL_PLAN_PARNERUS
              ? "Parnerus"
              : "Freemium",
          dueDate: subscription.billing_info?.next_billing_time,
        };
      } else if (
        user.id === "8864c717-587d-472a-929a-8e5f298024da-0" ||
        user.id === "9fd04264-97c8-486e-8aee-03420b8c78dc"
      ) {
        generatedSubscription = {
          plan: "Parnerus",
          dueDate: "2030-01-01",
        };
      } else {
        generatedSubscription = {
          plan: "Freemium",
          dueDate: null,
        };
      }

      dispatch({
        type: Types.LOGIN,
        payload: {
          user: { ...user, subscription: generatedSubscription },
        },
      });
    },
    []
  );

  // REGISTER
  const register = useCallback(
    async (
      accountName: string,
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      phoneNumber: string,
      pushToken: string
    ) => {
      const response = await axios.post("/api/account/register", {
        accountName,
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
        pushToken,
      });
      const { accessToken, user } = response.data;

      localStorage.setItem("accessToken", accessToken);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user,
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    setAccountSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // LOGIN ACCOUNT
  const loginAccount = useCallback((account: dbAccount) => {
    setAccountSession(account.accountId);
    dispatch({
      type: Types.LOGIN_ACCOUNT,
      payload: {
        account,
      },
    });
  }, []);

  // LOGOUT ACCOUNT
  const logoutAccount = useCallback(() => {
    setAccountSession(null);
    dispatch({
      type: Types.LOGOUT_ACCOUNT,
      payload: {
        account: null,
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      isAccountAuthenticated: state.isAccountAuthenticated,
      isVerifiedUser: state.isVerifiedUser,
      user: state.user,
      account: state.account,
      method: "jwt",
      login,
      loginWithGoogle: () => {},
      loginWithGithub: () => {},
      loginWithTwitter: () => {},
      register,
      logout,
      loginAccount,
      logoutAccount,
      initialize,
    }),
    [
      state.isAuthenticated,
      state.isAccountAuthenticated,
      state.isVerifiedUser,
      state.isInitialized,
      state.user,
      state.account,
      login,
      logout,
      register,
      loginAccount,
      logoutAccount,
      initialize,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
